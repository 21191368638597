import * as React from 'react';
import { useRef } from 'react';

const Newsletter = (): JSX.Element => {
    const inputRef = useRef(null);
    const handleSubmit = async (e) => {
        e.preventDefault();

        const req = await fetch('/api/mailchimp', {
            method: 'POST',
            body: JSON.stringify({ email: inputRef.current.value })
        });

        const response = await req.json();
        if (response.error) {
            window.alert(response.error);
        }
        if (response.body) {
            window.alert(response.body);
        }
        inputRef.current.value = '';
    };

    return (
        <section className={'page-container'}>
            <form
                onSubmit={handleSubmit}
                className={
                    'newsletter-form p-10 lg:px-12 lg:pt-10 lg:pb-14 lg:w-4/5 mx-auto m-0 h-full max-w-screen-md shadow-2xl relative rounded-xl overflow-hidden'
                }>
                <h4 className={'m-0 mb-5 text-green-main text-center font-light text-3xl'}>
                    DevsBrasil na sua caixa de entrada!
                </h4>
                <p className={'text-green-dark mt-0 mx-auto mb-8 lg:w-4/5 text-center'}>
                    Nada de spam ou flood, assine a newsletter e receba as novidades, posts e vídeos{' '}
                    <strong>quinzenalmente</strong>!
                </p>
                <label className={'block relative overflow-hidden z-10 rounded-lg'}>
                    <input
                        type="email"
                        required
                        name="email"
                        ref={inputRef}
                        className={
                            'text-xl w-full border-0 h-16 font-light outline-none text-green-dark py-0 px-5 text-center sm:text-left sm:pr-36 sm:pl-5'
                        }
                    />
                    <button
                        className={
                            'inline-hover w-full border-0 h-full p-2.5 bg-green-main text-white uppercase outline-none font-semibold tracking-wide hover:bg-green-dark focus:bg-green-dark text-sm sm:absolute sm:top-0 sm:right-0 sm:w-auto sm:rounded-xl sm:border-8 sm:border-white sm:px-8'
                        }>
                        Enviar
                    </button>
                </label>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={
                        'absolute left-0 right-0 w-full opacity-5 -top-1/3 sm:-top-3/4 text-transparent'
                    }>
                    <defs>
                        <linearGradient id="MyGradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#1F391D" />
                            <stop offset="80%" stopColor="transparent" />
                        </linearGradient>
                    </defs>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        className={'fill-current stroke-2'}
                        style={{
                            stroke: 'url(#MyGradient)'
                        }}
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                </svg>
            </form>
        </section>
    );
};

export default Newsletter;
